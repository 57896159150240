import type { Media } from 'types';
import React from 'react';

import Image from 'next/image';
import { Paragraph } from '@leafwell/components';

export type BagdeProps = {
  label?: string;
  icon?: Omit<Media, 'srcSet'>;
};

const HeroBadge: React.FC<BagdeProps> = ({ icon, label }) => (
  <article className="flex flex-col gap-y-2 xl:flex-row gap-x-2 items-center">
    <Image
      src={icon.src}
      width={48}
      height={48}
      alt="values header icon"
      quality={50}
      priority={true}
      className="flex h-auto justify-center items-end"
    />
    <Paragraph className="max-w-24 xl:max-w-28	text-xs xl:text-sm font-medium text-center xl:text-left">
      {label}
    </Paragraph>
  </article>
);

export default HeroBadge;
